<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col class="text-left">
                        <v-btn text :to="{name: 'admin.developers.index'}">return</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        {{ developer.name }}
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in russian" placeholder="Please enter a name in russian" outlined  v-model="developer.name_ru" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in english" placeholder="Please enter a name in english" outlined  v-model="developer.name_en" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in armenian" placeholder="Please enter a name in armenian" outlined  v-model="developer.name_hy" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Slug" placeholder="Please enter a slug (english only)" outlined v-model="developer.slug" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field  placeholder="Please enter a description in armenian"  label="Description in armenian"
                                          v-model="developer.description_hy" outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field  placeholder="Please enter a description in russian" label="Description in russian"
                                          v-model="developer.description_ru" outlined ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field   placeholder="Please enter a description in english" label="Description in english"
                                          v-model="developer.description_en" outlined ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Phone" placeholder="Please enter a phone"
                                          outlined v-model="developer.phone"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field label="Working time" placeholder="Please enter working time"
                                          outlined v-model="developer.working_time" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div>
                                <avatar-edit
                                    :current_url="developer.logo_url"
                                    @input="handleSaveDeveloper"
                                    v-model="developer.logo_url"
                                ></avatar-edit>
                            </div>
                            <div>
                                <v-text-field label="Logo URL" v-model="developer.logo_url" disabled></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveDeveloper">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" color="green" :timeout="timeout_snackbar">{{ text_snackbar }}</v-snackbar>
    </admin-dashboard-layout>
</template>

<script>
import Developer from "../../../models/Developer";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import AvatarEdit from "../../../components/AvatarEdit";

export default {
    name: "developers-show",
    components: {AdminDashboardLayout, AvatarEdit},
    data: function () {
        return {
            developer: {},
            formValid: true,
            nameRules: [
                v => !!v || 'Name is required',
            ],
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,
        }
    },
    methods: {
        async handleSaveDeveloper() {
            this.formValid = this.$refs.form.validate()

            if (this.formValid) {
                const developer = new Developer(this.developer);
                this.developer = await developer.save()
                await this.$router.push({name: 'admin.developers.show', params: {id: this.developer.id}}).catch(()=>{});
                this.snackbar = true
            }
        }
    },
    async mounted() {
        const developerId = this.$route.params.id
        if (developerId) {
            this.developer = await Developer.find(developerId)
        }
    },
}
</script>

<style scoped>

</style>
